export default {
  table: {
    tableWrapper: 'table-responsive',
    tableHeaderClass: 'mb-0',
    tableBodyClass: 'mb-0',
    tableClass: 'table b-table table-striped table-hover',
    loadingClass: 'loading',
    ascendingIcon: 'fa fa-chevron-up',
    descendingIcon: 'fa fa-chevron-down',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'fa fa-sort',
    detailRowClass: 'vuetable-detail-row',
    handleIcon: 'fa fa-bars text-secondary',
    renderIcon(classes) {
      return `<i class="${classes.join(' ')}"></i>`;
    },
  },
  pagination: {
    infoClass: 'pull-left',
    wrapperClass: 'pagination b-pagination pagination-md justify-content-center',
    activeClass: 'btn btn-info active',
    disabledClass: 'disabled',
    pageClass: 'btn page-link',
    linkClass: 'btn page-link',
    icons: {
      first: '',
      prev: '',
      next: '',
      last: '',
    },
  }
}