var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      staticClass: "custom-data-table-filter",
      attrs: {
        id: `filter-input-group-${_vm.name}`,
        "label-for": `filter-input-${_vm.name}`,
        label: _vm.filterProps.props.label || "⠀",
      },
    },
    [
      _vm.type === "textfield"
        ? [
            _c("b-form-input", {
              attrs: {
                id: `filter-input-${_vm.name}`,
                type: "text",
                placeholder: `${_vm.filterProps.props.placeholder}`,
              },
              model: {
                value: _vm.input_value,
                callback: function ($$v) {
                  _vm.input_value = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "input_value",
              },
            }),
          ]
        : _vm._e(),
      _vm.type === "select"
        ? [
            _c(
              "b-form-select",
              _vm._b(
                {
                  attrs: { id: `filter-input-${_vm.name}` },
                  model: {
                    value: _vm.input_value,
                    callback: function ($$v) {
                      _vm.input_value =
                        typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "input_value",
                  },
                },
                "b-form-select",
                _vm.filterProps.props,
                false
              )
            ),
          ]
        : _vm._e(),
      _vm.type === "datepicker"
        ? [
            _c(
              "datepicker",
              _vm._b(
                {
                  attrs: {
                    id: `filter-input-${_vm.name}`,
                    lang: _vm.getLanguage,
                  },
                  model: {
                    value: _vm.input_value,
                    callback: function ($$v) {
                      _vm.input_value = $$v
                    },
                    expression: "input_value",
                  },
                },
                "datepicker",
                _vm.filterProps.props,
                false
              )
            ),
          ]
        : _vm._e(),
      _vm.type === "nav"
        ? [
            _c(
              "b-nav",
              _vm._b(
                {
                  attrs: { id: `filter-link-${_vm.name}`, lang: "pt-br" },
                  model: {
                    value: _vm.input_value,
                    callback: function ($$v) {
                      _vm.input_value = $$v
                    },
                    expression: "input_value",
                  },
                },
                "b-nav",
                _vm.filterProps.props,
                false
              )
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }