/* eslint-disable vue/require-default-prop */
<template>
  <section class="p-0 custom-data-table">
    <transition v-if="loading" name="fade">
      <div class="__loader">
        <i class="__loader-icon fa fa-spin fa-cog" />
      </div>
    </transition>
    <div class="custom-data-table__filters" data-test="custom-data-table__filters">
      <form @submit.prevent="$_search">
        <b-container fluid class="p-0">
          <b-form-row>
            <b-col v-for="(filter, index) in filters" :key="`custom-table-filter-${index}`" xs="12" :sm="filter.cols || filter_grid">
              <custom-data-table-filter
                v-model="raw_filters[index]"
                :name="index"
                :type="filter.type"
                :filter-props="filter"
                @input="changeFilterValue(index, $event)"
              />
            </b-col>
            <b-col v-if="Object.keys(filters).length > 0" xs="12" :sm="1">
              <b-form-group>
                <label style="color: transparent">Search</label>
                <b-button type="submit" variant="info" class="custom-data-table__search-btn mt-0" block>
                  <i class="fa fa-search" />
                </b-button>
              </b-form-group>
            </b-col>

            <b-col v-if="hasCustomButton && Object.keys(filters).length > 0" xs="12" :sm="3">
              <slot v-if="hasCustomButton" name="custom_button" />
            </b-col>
          </b-form-row>
        </b-container>
      </form>
    </div>
    <b-card body-class="p-0">
      <b-card-body class="animated fadeIn p-0">
        <vuetable
          ref="vuetable"
          class="custom-data-table__vuetable"
          v-bind="$attrs"
          :api-mode="false"
          :data-manager="$_dataManager"
          :per-page="perPage"
          pagination-path="pagination"
          data-test="custom-data-table__table"
          :css="css.table"
          v-on="$listeners"
          @vuetable:pagination-data="$_onPaginationData"
        >
          <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
            <slot :name="slot" v-bind="props" />
          </template>
        </vuetable>
      </b-card-body>
    </b-card>
    <div class="custom-data-table__pagination">
      <div v-if="perPage">
        <vuetable-pagination
          ref="pagination"
          class="custom-data-table__vuetable-pagination"
          data-test="custom-data-table__pagination"
          :css="css.pagination"
          @vuetable-pagination:change-page="$_onChangePage"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination';
import assignDeep from 'assign-deep';
import CustomDataTableFilter from './_CustomDataTableFilter';
import CustomDataTableStyle from './CustomDataTableStyle.js';
import ApolloClient from '@utils/graphql';
const API_TYPES = ['rest', 'graphql'];

export default {
  components: {
    Vuetable,
    VuetablePagination,
    CustomDataTableFilter,
  },
  inheritAttrs: true,
  props: {
    apiType: {
      type: String,
      default: 'graphql',
      validator: value => API_TYPES.includes(value),
    },
    filters: {
      type: Object,
      default: () => {},
    },
    fixedFilters: {
      type: Object,
      default: () => {},
    },
    isDriver: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 20,
    },
    query: {
      type: Object,
      default: () => {},
    },
    queryContext: {
      type: String,
      default: '',
    },
    queryInterface: {
      type: Number,
      default: 1,
    },
    queryIterator: {
      type: String,
      default: 'items',
    },
    queryNode: {
      type: String,
      required: true,
    },
    queryVariables: {
      type: Object,
      default: () => {},
    },
    sort: {
      type: Array,
      default: () => [],
    },
    startPage: {
      type: Boolean,
      default: false,
    },
    cache: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      data: {},
      tableData: [],
      raw_filters: {},
      page: 1,
      loading: false,
      pagination_data: null,
      sort_params: null,
      css: CustomDataTableStyle,
    };
  },
  computed: {
    filter_grid() {
      let columns = 12;
      switch (Object.keys(this.filters).length + 1) {
        case 1:
        case 2:
          columns = 6;
          break;
        case 3:
          columns = 4;
          break;
        case 5:
          columns = 2;
          break;
        default:
          columns = 3;
          break;
      }
      return columns;
    },
    is_graphql() {
      return this.apiType === 'graphql';
    },
    is_rest() {
      return this.apiType === 'rest';
    },
    merged_filters() {
      let filtersObj = {};
      for (const filter in this.raw_filters) {
        filtersObj = assignDeep(filtersObj, this.raw_filters[filter]);
      }
      filtersObj = assignDeep(filtersObj, this.fixedFilters);
      return filtersObj;
    },
    query_variables() {
      let page = this.page;
      if (!this.queryContext && !this.startPage) page = page - 1;
      let variables = {
        filter: {
          ...this.merged_filters,
        },
        limit: this.perPage,
        ...this.queryVariables,
        page,
      };

      if (!this.queryContext) {
        page = page - 1;
      }

      if (this.queryInterface === 2) {
        variables = {
          filters: {
            where: this.merged_filters,
          },
          order: this.sort_params || this.sort,
          ...variables,
        };
      } else {
        variables = {
          filters: {
            where: this.merged_filters,
            order: this.sort_params || this.sort,
          },
          ...variables,
        };
      }

      return variables;
    },
    hasCustomButton() {
      return !!this.$slots['custom_button'];
    },
  },
  watch: {
    raw_filters: {
      handler(value) {
        this.$_emit(value);
      },
      deep: true,
    },
  },
  mounted() {
    this.$_getData();
  },
  methods: {
    changeFilterValue(filterName, event) {
      this.$emit('filter-change', { filterName, event });
    },
    $_dataManager(sortOrder, pagination) {
      if (Array.isArray(sortOrder) && sortOrder.length > 0) {
        let objSort;

        if (this.queryInterface === 2) {
          let direction = sortOrder[0].direction == 'asc' ? 'ASCENDING' : sortOrder[0].direction == 'desc' ? 'DESCENDING' : '';

          objSort = {
            field: sortOrder[0].field,
            direction,
          };
        } else {
          objSort = [[sortOrder[0].field, sortOrder[0].direction.toUpperCase()]];
        }

        if (JSON.stringify(objSort) !== JSON.stringify(this.sort_params)) {
          this.sort_params = objSort;
          this.$_getData();
        }
      }
      pagination = this.$refs.vuetable.makePagination(this.$_getCount(this.data), this.perPage, this.page);
      return {
        pagination,
        data: this.tableData,
      };
    },
    $_getCount({ count = null, total = null }) {
      return count || total;
    },
    $_getData() {
      if (this.is_graphql) {
        this.loading = true;
        return this.$apollo
          .query({
            query: this.query,
            variables: this.query_variables,
            fetchPolicy: this.cache ? 'network-only' : 'no-cache',
          })
          .then(response => {
            this.loading = false;
            if (this.queryContext) {
              this.data = response.data[this.queryContext][this.queryNode];
              this.data.count = this.$_getCount(response.data[this.queryContext][this.queryNode]);
              this.tableData = response.data[this.queryContext][this.queryNode][this.queryIterator];
            } else {
              if (this.queryIterator !== 'items') {
                this.data = response.data[this.queryNode][this.queryIterator];
                this.tableData = response.data[this.queryNode][this.queryIterator]['items'];
                this.data.count = this.$_getCount(response.data[this.queryNode][this.queryIterator]);
              } else {
                this.data = response.data[this.queryNode];
                this.data.count = this.$_getCount(response.data[this.queryNode]);
                this.tableData = response.data[this.queryNode][this.queryIterator];
              }
            }
            this.$refs.vuetable.reload();
          })
          .catch(err => {
            throw new Error(err);
          });
      }
    },
    $_onChangePage(page) {
      if (page === 'prev') {
        page = this.page - 1;
      } else if (page === 'next') {
        page = this.page + 1;
      }
      this.page = page;
      this.$refs.vuetable.changePage(page);
      this.$_getData();
    },
    $_onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    async $_refresh() {
      await ApolloClient.cache.reset();
      this.$_getData();
    },
    async $_search() {
      this.page = 1;
      await ApolloClient.cache.reset();
      this.$_getData().then(this.$refs.vuetable.refresh);
    },
    $_emit(value) {
      this.$emit('filter-change', { merged: this.merged_filters, select: value });
    },
  },
};
</script>

<style lang="sass" scoped>
.custom-data-table__vuetable
  margin-bottom: 0;
.custom-data-table__pagination
  margin-bottom: 50px;
.custom-data-table
  position: relative
  .__loader
    position: absolute
    display: flex
    align-items: center
    justify-content: center
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(255, 255, 255, 0.6)
    z-index: 999
    pointer-events: none
  .__loader-icon
    display: block
    font-size: 80px
  .__filters
    margin: 20px 0
  .__button-legend
    margin-bottom: 0
    height: 24px
  .__search-btn
    color: #fff
    margin-top: 0
    &:hover
      color: #fff
  .page-link
    margin: 0 2px
    &.disabled
      opacity: 0.6
      pointer-events: none
    &.active
      pointer-events: none
</style>
