<template>
  <b-form-group
    :id="`filter-input-group-${name}`"
    class="custom-data-table-filter"
    :label-for="`filter-input-${name}`"
    :label="filterProps.props.label || '⠀'"
  >
    <template v-if="type === 'textfield'">
      <b-form-input
        :id="`filter-input-${name}`"
        v-model.trim="input_value"
        type="text"
        :placeholder="`${filterProps.props.placeholder}`"
      />
    </template>

    <template v-if="type === 'select'">
      <b-form-select
        :id="`filter-input-${name}`"
        v-model.trim="input_value"
        v-bind="filterProps.props"
      />
    </template>

    <template v-if="type === 'datepicker'">
      <datepicker
        :id="`filter-input-${name}`"
        v-model="input_value"
        v-bind="filterProps.props"
        :lang="getLanguage"
      />
    </template>

    <template v-if="type === 'nav'">
      <b-nav
        :id="`filter-link-${name}`"
        v-model="input_value"
        v-bind="filterProps.props"
        lang="pt-br"
      />
    </template>
  </b-form-group>
</template>

<style lang="scss">

</style>

<script>
const FIELD_TYPES = ['nav', 'select', 'textfield', 'datepicker'];
import { currentLanguage } from '@utils/language';

export default {
  props: {
    type: {
      type: String,
      default: 'textfield',
      validator: value => FIELD_TYPES.includes(value),
    },
    filterProps: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      input_value: this.filterProps.value,
    };
  },
  computed: {
    getLanguage() {
      return currentLanguage;
    },
    parsed_value() {
      if (typeof this.filterProps.valueParserFunction === 'function') {
        return this.filterProps.valueParserFunction(this.input_value);
      } else {
        return this.input_value;
      }
    },
  },
  watch: {
    input_value() {
      this.$_emit();
    },
  },
  methods: {
    $_emit() {
      let queryObj = {};
      queryObj = this.filterProps.graphqlQuery(this.parsed_value);
      this.$emit('input', queryObj);
    },
  },
};
</script>
