var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "p-0 custom-data-table" },
    [
      _vm.loading
        ? _c("transition", { attrs: { name: "fade" } }, [
            _c("div", { staticClass: "__loader" }, [
              _c("i", { staticClass: "__loader-icon fa fa-spin fa-cog" }),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "custom-data-table__filters",
          attrs: { "data-test": "custom-data-table__filters" },
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.$_search.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-container",
                { staticClass: "p-0", attrs: { fluid: "" } },
                [
                  _c(
                    "b-form-row",
                    [
                      _vm._l(_vm.filters, function (filter, index) {
                        return _c(
                          "b-col",
                          {
                            key: `custom-table-filter-${index}`,
                            attrs: {
                              xs: "12",
                              sm: filter.cols || _vm.filter_grid,
                            },
                          },
                          [
                            _c("custom-data-table-filter", {
                              attrs: {
                                name: index,
                                type: filter.type,
                                "filter-props": filter,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changeFilterValue(index, $event)
                                },
                              },
                              model: {
                                value: _vm.raw_filters[index],
                                callback: function ($$v) {
                                  _vm.$set(_vm.raw_filters, index, $$v)
                                },
                                expression: "raw_filters[index]",
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      Object.keys(_vm.filters).length > 0
                        ? _c(
                            "b-col",
                            { attrs: { xs: "12", sm: 1 } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "label",
                                    { staticStyle: { color: "transparent" } },
                                    [_vm._v("Search")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "custom-data-table__search-btn mt-0",
                                      attrs: {
                                        type: "submit",
                                        variant: "info",
                                        block: "",
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-search" })]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hasCustomButton && Object.keys(_vm.filters).length > 0
                        ? _c(
                            "b-col",
                            { attrs: { xs: "12", sm: 3 } },
                            [
                              _vm.hasCustomButton
                                ? _vm._t("custom_button")
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card",
        { attrs: { "body-class": "p-0" } },
        [
          _c(
            "b-card-body",
            { staticClass: "animated fadeIn p-0" },
            [
              _c(
                "vuetable",
                _vm._g(
                  _vm._b(
                    {
                      ref: "vuetable",
                      staticClass: "custom-data-table__vuetable",
                      attrs: {
                        "api-mode": false,
                        "data-manager": _vm.$_dataManager,
                        "per-page": _vm.perPage,
                        "pagination-path": "pagination",
                        "data-test": "custom-data-table__table",
                        css: _vm.css.table,
                      },
                      on: {
                        "vuetable:pagination-data": _vm.$_onPaginationData,
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm._l(_vm.$scopedSlots, function (_, slot) {
                            return {
                              key: slot,
                              fn: function (props) {
                                return [_vm._t(slot, null, null, props)]
                              },
                            }
                          }),
                        ],
                        null,
                        true
                      ),
                    },
                    "vuetable",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "custom-data-table__pagination" }, [
        _vm.perPage
          ? _c(
              "div",
              [
                _c("vuetable-pagination", {
                  ref: "pagination",
                  staticClass: "custom-data-table__vuetable-pagination",
                  attrs: {
                    "data-test": "custom-data-table__pagination",
                    css: _vm.css.pagination,
                  },
                  on: { "vuetable-pagination:change-page": _vm.$_onChangePage },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }